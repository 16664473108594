/*
=============== 
Fonts
===============
*/
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&family=Sono:wght@400;500;600;700&display=swap');
/*
=============== 
Variables
===============
*/

:root {
	/* dark shades of primary color*/
	--clr-primary-1: #312e81;
	--clr-primary-2: #3730a3;
	--clr-primary-3: #4338ca;
	--clr-primary-4: #4f46e5;
	--clr-primary-5: #6366f1;
	/* lighter shades of primary color */
	--clr-primary-6: #6366f1;
	--clr-primary-7: #a5b4fc;
	--clr-primary-8: #c7d2fe;
	--clr-primary-9: #e0e7ff;
	--clr-primary-10: #eef2ff;

	/* darkest grey - used for headings */
	--clr-grey-1: hsl(209, 61%, 16%);
	--clr-grey-3: hsl(209, 34%, 30%);
	--clr-grey-4: hsl(209, 28%, 39%);
	/* grey used for paragraphs */
	--clr-grey-5: hsl(210, 22%, 49%);
	--clr-grey-8: hsl(210, 31%, 80%);
	--clr-grey-9: hsl(212, 33%, 89%);
	--clr-grey-10: hsl(210, 36%, 96%);

	--clr-green-dark: hsl(125, 67%, 35%);
	--clr-red-dark: hsl(360, 67%, 44%);
	--clr-red-light: hsl(360, 71%, 66%);
	--clr-white: #fff;

	--ff-primary: 'Sono', sans-serif;
	--ff-secondary: 'Lora', sans-serif;

	--transition: all 0.3s linear;
	--spacing: 0.2rem;
	--radius: 0.25rem;

	--light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
	--dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
	--max-width: 1170px;
	--fixed-width: 700px;
}
/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
body {
	font-family: var(--ff-secondary);
	background: var(--clr-white);
	color: var(--clr-grey-1);
	line-height: 1.5;
	font-size: 0.875rem;
	margin-top: 5rem;
}
ul {
	list-style-type: none;
}
a {
	text-decoration: none;
}

h1,
h2,
h3,
h4 {
	letter-spacing: var(--spacing);
	text-transform: capitalize;
	line-height: 1.25;
	margin-bottom: 0.75rem;
	font-family: var(--ff-primary);
}
h1 {
	font-size: 3rem;
}
h2 {
	font-size: 2rem;
}
h3 {
	font-size: 1.25rem;
}
h4 {
	font-size: 0.875rem;
}
p {
	margin-bottom: 1.25rem;
	color: var(--clr-grey-3);
}
button {
	font-family: inherit;
}
@media screen and (min-width: 800px) {
	h1 {
		font-size: 4rem;
	}
	h2 {
		font-size: 2.5rem;
	}
	h3 {
		font-size: 1.75rem;
	}
	h4 {
		font-size: 1rem;
	}
	body {
		font-size: 1rem;
	}
	h1,
	h2,
	h3,
	h4 {
		line-height: 1;
	}
}
/*  global classes */

.btn {
	text-transform: uppercase;
	background: var(--clr-primary-5);
	color: var(--clr-primary-9);
	padding: 0.375rem 0.75rem;
	letter-spacing: var(--spacing);
	display: inline-block;
	font-weight: 700;
	-webkit-transition: var(--transition);
	transition: var(--transition);
	font-size: 0.875rem;
	border: 2px solid transparent;
	cursor: pointer;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
	border-radius: var(--radius);
}
.btn:hover {
	color: var(--clr-primary-1);
	background: var(--clr-primary-8);
}
.center-btn {
	display: block;
	width: 12rem;
	text-align: center;
	margin: 0 auto;
	margin-top: 3rem;
}
/* section */
.section {
	padding: 5rem 0;
}

.section-center {
	width: 90vw;
	margin: 0 auto;
	max-width: 1170px;
}
@media screen and (min-width: 992px) {
	.section-center {
		width: 95vw;
	}
}
/* added during recording */
.underline {
	width: 5rem;
	height: 0.25rem;
	margin-bottom: 1.25rem;
	background: var(--clr-primary-5);
	margin-left: auto;
	margin-right: auto;
}
.section-title {
	margin-bottom: 4rem;
	text-align: center;
}
.bg-grey {
	background: var(--clr-grey-10);
}
/* page links */

/* social links */
.social-links {
	margin-top: 2rem;
	width: 15rem;
	display: flex;
	justify-content: space-between;
}
.social-link {
	font-size: 1.75rem;
	color: var(--clr-grey-1);
	transition: var(--transition);
}
.social-link:hover {
	color: var(--clr-primary-5);
}
/*
=============== 
Navbar
===============
*/

.navbar {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 5rem;
	display: flex;
	align-items: center;
	z-index: 200;
	background: var(--clr-white);
}
.nav-center {
	width: 90vw;
	max-width: 1170px;
	margin: 0 auto;
}
.nav-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.logo {
	text-decoration: none;
	font-family: var(--ff-primary);
	font-weight: 600;
	font-size: 2rem;
	color: var(--clr-primary-4);
}
.nav-header img {
	margin-bottom: 0.375rem;
}
.toggle-btn {
	font-size: 2rem;
	background: transparent;
	border-color: transparent;
	color: var(--clr-primary-5);
	cursor: pointer;
	transition: var(--transition);
}
.toggle-btn:hover {
	color: var(--clr-primary-2);
}
.nav-links {
	font-family: var(--ff-primary);
	display: none;
}
@media screen and (min-width: 768px) {
	.toggle-btn {
		display: none;
	}
	.nav-links {
		display: flex;
		justify-content: flex-end;
	}

	.nav-links a {
		margin-right: 2rem;
		text-transform: capitalize;
		color: var(--clr-grey-1);
		font-weight: bold;
		letter-spacing: var(--spacing);
		transition: var(--transition);
		padding: 0.5rem 0;
	}
	.nav-links a:hover {
		color: var(--clr-primary-5);
		box-shadow: 0px 2px var(--clr-primary-5);
	}
	.nav-center {
		display: grid;
		grid-template-columns: auto 1fr;
		align-items: center;
	}
}

@media screen and (min-width: 992px) {
	.navbar {
		background: transparent;
	}
}
/*
=============== 
Sidebar
===============
*/
.sidebar {
	background: var(--clr-grey-10);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	display: grid;
	place-items: center;
	opacity: 0;
	transition: var(--transition);
	transform: translateX(-100%);
}
.show-sidebar {
	opacity: 1;
	transform: translateX(0);
}
.sidebar-links li {
	opacity: 0;
}
.sidebar-links li a {
	display: block;
	text-align: center;
	text-transform: capitalize;
	color: var(--clr-grey-5);
	letter-spacing: var(--spacing);
	margin-bottom: 0.5rem;
	font-size: 2rem;
	transition: var(--transition);
	border-radius: var(--radius);
}
.sidebar-links li a:hover {
	background: var(--clr-primary-9);
	color: var(--clr-primary-5);
}
.close-btn {
	position: absolute;
	right: 4.75%;
	top: 2.75%;
	font-size: 2.5rem;
	background: transparent;
	border-color: transparent;
	color: var(--clr-red-dark);
	cursor: pointer;
}
@media screen and (min-width: 992px) {
	.sidebar {
		transform: translateX(-100%);
	}
}
/*
=============== 
Sidebar Animation
===============
*/
.sidebar-links li {
	animation: slideRight 0.5s ease-in-out 0.3s forwards;
}
.sidebar-links li:nth-of-type(1) {
	animation-delay: 0.25s;
}
.sidebar-links li:nth-of-type(2) {
	animation-delay: 0.5s;
}
.sidebar-links li:nth-of-type(3) {
	animation-delay: 0.75s;
}
.sidebar-links li:nth-of-type(4) {
	animation-delay: 1s;
}
.sidebar-links li:nth-of-type(5) {
	animation-delay: 1.25s;
}
@keyframes slideRight {
	0% {
		transform: translateX(-200px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}
.sidebar-icons li {
	opacity: 0;
	animation: slideUp 0.5s ease-in-out 0.3s forwards;
}
.sidebar-icons li a {
	color: var(--clr-grey-1);
}
.sidebar-icons li:nth-of-type(1) {
	animation-delay: 0.25s;
}
.sidebar-icons li:nth-of-type(2) {
	animation-delay: 0.5s;
}
.sidebar-icons li:nth-of-type(3) {
	animation-delay: 0.75s;
}
.sidebar-icons li:nth-of-type(4) {
	animation-delay: 1s;
}
.sidebar-icons li:nth-of-type(5) {
	animation-delay: 1.25s;
}
@keyframes slideUp {
	0% {
		transform: translateY(200px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

/*
=============== 
Hero
===============
*/
/* underline added to globals */
.hero {
	margin-top: -5rem;
	padding-top: 5rem;
	height: 100vh;
	background: var(--clr-primary-10);
	position: relative;
}

.hero-center {
	height: 100%;
	display: grid;
	align-items: center;
}

.hero .underline {
	margin-bottom: 0.5rem;
	margin-left: 0;
}
.hero-info {
	background: var(--clr-primary-10);
}
.hero-img {
	display: none;
}
.hero-img-svg {
	display: none;
}
.hero-img {
	display: none;
}
.hero-info h4 {
	color: var(--clr-grey-5);
}
.hero-icons {
	justify-items: flex-start;
}
.hero .btn {
	margin-top: 1.25rem;
}

@media screen and (min-width: 992px) {
	.hero::before {
		content: '';
		position: absolute;
		top: 0;
		left: 60%;
		right: 0;
		bottom: 0;
		background: var(--clr-white);
	}
	.hero-center {
		grid-template-columns: repeat(12, 1fr);
	}

	.hero h4 {
		font-size: 0.85rem;
	}
	.hero-info {
		grid-row: 1/1;
		grid-column: 1 / span 8;
	}
	.hero-img {
		width: 80%;
		justify-self: center;
		display: block;
		grid-row: 1/1;
		grid-column: 7/-1;
		z-index: 10;
	}
	.hero-img-svg {
		width: 70%;
		position: relative;
		display: block;
		grid-row: 1/1;
		grid-column: 7/-1;
	}
}
@media screen and (min-width: 1170px) {
	.hero h4 {
		font-size: 1rem;
	}

	.hero-info {
		grid-column: 1 / span 8;
	}

	.hero h4 {
		line-height: 1;
	}
}

/*
=============== 
Keywords
===============
*/
.keyword {
	background: var(--clr-white);
	padding: 3rem 1.5rem;
	margin-bottom: 2rem;
	border-radius: var(--radius);
	-webkit-transition: var(--transition);
	transition: var(--transition);
}
.keyword h4 {
	text-align: center;
}
.keyword-icon {
	text-align: center;
	font-size: 2rem;
}
.keyword .underline {
	width: 3rem;
	height: 0.12rem;
	-webkit-transition: var(--transition);
	transition: var(--transition);
}
.keyword p {
	-webkit-transition: var(--transition);
	transition: var(--transition);
}
.keyword:hover {
	background: var(--clr-primary-5);
	color: var(--clr-primary-9);
}
.keyword:hover p {
	color: var(--clr-primary-9);
}
.keyword:hover .underline {
	background: var(--clr-primary-9);
}
@media screen and (min-width: 676px) {
	.keywords-center {
		display: grid;
		grid-template-columns: 1fr 1fr;
		-webkit-column-gap: 2rem;
		-moz-column-gap: 2rem;
		column-gap: 2rem;
	}
}
@media screen and (min-width: 992px) {
	.keywords-center {
		grid-template-columns: 1fr 1fr 1fr;
	}
}

/*
=============== 
Jobs
===============
*/

.jobs-center {
	width: 80vw;
	margin: 0 auto;
	max-width: var(--max-width);
}
.btn-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: 4rem;
}
.job-btn {
	font-family: inherit;
	background: transparent;
	border-color: transparent;
	text-transform: capitalize;
	font-size: 1.25rem;
	letter-spacing: var(--spacing);
	margin: 0.5rem;
	transition: var(--transition);
	cursor: pointer;
	padding: 0.25rem 0;
	line-height: 1;
}
.job-btn:hover {
	color: var(--clr-primary-5);
	box-shadow: 0 2px var(--clr-primary-5);
}
.active-btn {
	color: var(--clr-primary-5);
	box-shadow: 0 2px var(--clr-primary-5);
}
.job-info h3 {
	font-weight: 400;
}
.job-info h4 {
	text-transform: uppercase;
	color: var(--clr-grey-5);
	background: var(--clr-grey-9);
	display: inline-block;
	padding: 0.375rem 0.75rem;
	border-radius: var(--radius);
}
.job-date {
	font-size: 0.75rem;
	letter-spacing: 1px;
}
.job-desc {
	display: grid;
	grid-template-columns: auto 1fr;
	column-gap: 2rem;
	align-items: center;
	margin-bottom: 1.25rem;
}
.job-desc p {
	margin-bottom: 0;
	color: var(--clr-grey-3);
}
.job-icon {
	color: var(--clr-primary-5);
}
@media screen and (min-width: 992px) {
	.jobs-center {
		width: 90vw;
		display: grid;
		grid-template-columns: 200px 1fr;
		column-gap: 4rem;
	}
	.btn-container {
		flex-direction: column;
		justify-content: flex-start;
	}

	.active-btn {
		box-shadow: -2px 0 var(--clr-primary-5);
	}
	.job-btn:hover {
		box-shadow: -2px 0 var(--clr-primary-5);
	}
	.job-date {
		font-size: 1rem;
		letter-spacing: var(--spacing);
	}
}

/*
=============== 
Projects
===============
*/
.projects {
	background: var(--clr-grey-10);
}
.project {
	display: grid;
	margin-bottom: 4rem;
}
.type-center {
	font-size: 2rem;
	font-family: inherit;
	display: grid;
	margin-bottom: 1rem;
	row-gap: 0.5rem;
}
.type-center .active {
	background: var(--clr-primary-2);
	color: var(--clr-white);
}
.project-img {
	border-top-left-radius: var(--radius);
	border-top-right-radius: var(--radius);
	height: 19rem;
	z-index: 1;
	position: relative;
}
.project-info {
	background: var(--clr-white);
	padding: 1rem 2rem;
	border-bottom-left-radius: var(--radius);
	border-bottom-right-radius: var(--radius);
}
.project-info p {
	margin: 0.2rem auto;
	word-spacing: 2px;
}
.project-number {
	display: inline-block;
	font-size: 1.25rem;
	color: var(--clr-primary-5);
	margin-bottom: 0.75rem;
}
.project-slug h3 {
	font-weight: 500;
	font-size: 1.25rem;
	margin-bottom: 1.5rem;
	text-transform: uppercase;
	color: var(--clr-grey-1);
	transition: var(--transition);
}
.project-slug h3:hover {
	color: var(--clr-primary-5);
}
.project-slug span {
	font-weight: 400;
	font-size: 0.75rem;
}
.project-desc {
	word-spacing: 15px;
	color: var(--clr-grey-3);
}
.project-stack {
	margin-bottom: 1rem;
}
.project-stack span,
.about-stack span {
	display: inline-block;
	background: var(--clr-grey-9);
	color: var(--clr-grey-5);
	margin: 0.25rem;
	padding: 0.25rem 0.5rem;
	border-radius: var(--radius);
	text-transform: uppercase;
	letter-spacing: 2px;
	font-size: 0.85rem;
}
.about-stack span {
	margin-top: 0.5rem;
}
.project-icon {
	color: var(--clr-primary-5);
	font-size: 1.25rem;
	margin-right: 1rem;
	transition: var(--transition);
}
.project-icon:hover {
	color: var(--clr-primary-1);
}
@media screen and (min-width: 576px) {
	.project-img {
		height: 19rem;
	}
}
@media screen and (min-width: 768px) {
	.project-img {
		height: 22rem;
	}
}
@media screen and (min-width: 992px) {
	.project {
		grid-template-columns: repeat(12, 1fr);
		align-items: center;
	}
	.project-img {
		grid-column: 1 / span 8;
		/* grid-column-end: 8; */
		grid-row: 1 / 1;
		height: 30rem;
		border-radius: var(--radius);
		box-shadow: var(--dark-shadow);
	}
	.project-info {
		border-radius: var(--radius);
		box-shadow: var(--dark-shadow);

		z-index: 1;
		grid-column: 5 /12;
		grid-row: 1 / 1;
	}
	.project:nth-of-type(even) .project-img {
		grid-column: 5 / -1;
		grid-row: 1 / 1;
	}
	.project:nth-of-type(even) .project-info {
		grid-column: 2 / span 7;
		grid-row: 1 / 1;
		text-align: left;
	}
	.type-center {
		font-size: 2rem;
		font-family: inherit;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		column-gap: 3rem;
		margin-bottom: 3rem;
	}
}

/*
===============
Footer
===============
*/
.footer {
	height: 9rem;
	background: #222;
	text-align: center;
	display: grid;
	place-items: center;
}
.footer h4 {
	margin-top: 0.5rem;
	color: var(--clr-white);
	font-weight: normal;
	text-transform: uppercase;
}
.footer h4 span {
	color: var(--clr-primary-5);
}
.footer-links {
	margin: 0 auto 1rem auto;
}
.footer .social-link {
	color: var(--clr-white);
}
.footer .social-link:hover {
	color: var(--clr-primary-5);
}
/*
===============
Pages
===============
*/

.project-template-page,
.projects-page,
.error-page,
.about-page,
.contact-page,
.project-detail-page {
	background: var(--clr-grey-10);
	min-height: calc(100vh - 5rem - 9rem);
}
.error-page {
	display: grid;
	place-items: center;
	background: var(--clr-primary-10);
	text-align: center;
}
.error-page h1 {
	text-transform: uppercase;
	color: var(--clr-primary-1);
	margin-bottom: 2rem;
}

/*
===============
Project Template Page
===============
*/
.project-template-page {
	padding: 3rem 3rem;
}
.project-template-page .stack {
	text-align: center;
	padding-top: 3rem;
}
.project-template-page h1 {
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 1rem;
}
.project-template-page h2 {
	font-size: 1.5rem;
	/* text-align: center; */
	margin: 2rem auto;
	text-transform: capitalize;
}
.project-template-page h4 {
	margin-top: 1rem;
	text-transform: capitalize;
}
.project-template-page p {
	/* max-width: 35em; */
	margin: 0 auto;
	margin-bottom: 0.5rem;
}
.project-template-page a {
	font-size: 1.2rem;
	font-weight: 700;
	color: var(--clr-primary-6);
}
.single-project-img {
	border-radius: var(--radius);
	box-shadow: var(--dark-shadow);
	width: 100%;
}
/*
===============
About Page
===============
*/
.about-page {
	padding: 7rem 1rem;
}
.about-stack {
	margin-bottom: 3rem;
}
.about-me {
	display: grid;
	justify-items: center;
	margin-top: 3rem;
}
.about-me h5 {
	margin-bottom: 3rem;
}
.image-container {
	padding: 0;
	display: grid;
	grid-template-columns: 1fr;
	row-gap: 2rem;

	margin-bottom: 3rem;
}

.about-img {
	justify-self: center;
	width: 70%;
}
.about-img-svg {
	margin-bottom: 2rem;
	height: 40rem;
	width: 100%;
	display: none;
}
.about-text .section-title {
	margin-bottom: 2rem;
	text-align: center;
}
/* .about-text .underline {
	margin-left: 0;
	margin-right: 0;
} */
.about-text p {
	line-height: 2;
	margin-bottom: 0.5rem;
}
.stack-container {
	width: 30rem;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	row-gap: 0.5rem;
}
.stack-container span {
	text-transform: uppercase;
	display: flex;
	align-items: center;
	letter-spacing: 1px;
	color: var(--clr-grey-4);
}
.stack-icon {
	color: var(--clr-primary-5);
	margin-right: 0.25rem;
	font-size: 1rem;
}
@media screen and (min-width: 992px) {
	.about-center {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		align-items: center;
		column-gap: 3rem;
	}
	.about-text .section-title {
		text-align: left;
	}
	.about-text .underline {
		margin-left: 0;
		margin-right: 0;
	}
	.image-container {
		grid-template-columns: repeat(4, 1fr);
		column-gap: 2rem;

		padding: 0 4rem;
	}
	.image-container.photo {
		grid-template-columns: repeat(2, 1fr);
		column-gap: 2rem;
	}
	.about-img {
		width: 100%;
	}
	.photo .about-img {
		width: 70%;
	}
	.photo .about-img:nth-child(odd) {
		justify-self: end;
	}

	.about-img-svg {
		display: block;
		grid-column: 1 / span 5;
		margin-bottom: 0;
	}
	.about-text {
		grid-column: 6 / -1;
	}
}
/*
===============
Contact Page
===============
*/
.contact-page {
	display: grid;
	place-items: center;
	padding: 5rem 0;
}
.contact-form {
	background: var(--clr-white);
	border-radius: var(--radius);
	text-align: center;
	box-shadow: var(--light-shadow);
	transition: var(--transition);
	width: 90vw;
	max-width: 35rem;
}
.contact-form:hover {
	box-shadow: var(--dark-shadow);
}
.contact-form h3 {
	padding-top: 1.25rem;
	color: var(--clr-grey-5);
}
.form-group {
	padding: 1rem 1.5rem;
}
.form-control {
	display: block;
	width: 100%;
	padding: 0.75rem 1rem;
	border: none;
	margin-bottom: 1.25rem;
	background: var(--clr-grey-10);
	border-radius: var(--radius);
	text-transform: uppercase;
	letter-spacing: var(--spacing);
}
.form-control::placeholder {
	font-family: var(--ff-primary);
	color: var(--clr-grey-1);
	text-transform: uppercase;
	letter-spacing: var(--spacing);
}
.submit-btn {
	display: block;
	width: 100%;
	padding: 1rem;
	border-bottom-left-radius: var(--radius);
	border-bottom-right-radius: var(--radius);
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}
